[data-font-size="small"] {
  --font-size-xs: 8px;
  --font-size-sm: 9px;
  --font-size-base: 10px;
  --font-size-lg: 12px;
  --font-size-xl: 14px;
  --font-size-2xl: 16px;
  --font-size-3xl: 18px;
  --font-size-4xl: 20px;
  --font-size-5xl: 24px;
  --font-size-6xl: 30px;
  --font-size-7xl: 36px;
  --font-size-8xl: 48px;
  --font-size-9xl: 60px;
}
