.dot {
  -webkit-animation: load 1.6s infinite;
  -moz-animation: load 1.6s infinite;
  -o-animation: load 1.6s infinite;
  animation: load 1.6s infinite;
}

.dot:nth-child(1) {
  background-color: rgb(var(--color-primary-50));
  opacity: 0.2;
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  -o-animation-delay: .1s;
  animation-delay: .1s;
}

.dot:nth-child(2) {
  background-color: rgb(var(--color-primary-50));
  opacity: 0.4;
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  -o-animation-delay: .3s;
  animation-delay: .3s;
}

.dot:nth-child(3) {
  background-color: rgb(var(--color-primary-50));
  opacity: 0.6;
  -webkit-animation-delay: .5s;
  -moz-animation-delay: .5s;
  -o-animation-delay: .5s;
  animation-delay: .5s;
}

.dot:nth-child(4) {
  background-color: rgb(var(--color-primary-50));
  opacity: 0.8;
  -webkit-animation-delay: .7s;
  -moz-animation-delay: .7s;
  -o-animation-delay: .7s;
  animation-delay: .7s;
}

.dot:nth-child(5) {
  background-color: rgb(var(--color-primary-50));
  -webkit-animation-delay: .9s;
  -moz-animation-delay: .9s;
  -o-animation-delay: .9s;
  animation-delay: .9s;
}

@-webkit-keyframes load {
  0%, 100% {
    -webkit-transform: scale(0.0);
  }
  50% {
    -webkit-transform: scale(1.0);
  }
}

@-moz-keyframes load {
  0%, 100% {
    -moz-transform: scale(0.0);
  }
  50% {
    -moz-transform: scale(1.0);
  }
}

@-o-keyframes load {
  0%, 100% {
    -o-transform: scale(0.0);
  }
  50% {
    -o-transform: scale(1.0);
  }
}

@keyframes load {
  0%, 100% {
    transform: scale(0.0);
  }
  50% {
    transform: scale(1.0);
  }
}
