.item {
  animation: loader 2s infinite ease-in-out;
  -webkit-animation: loader 2s infinite ease-in-out;
}

@keyframes loader {
  0% {
    opacity: 0.4;
  }
  30% {
    opacity: 0.6;
  }
  60% {
    opacity: 0.8
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes loader {
  0% {
    opacity: 0.4;
  }
  30% {
    opacity: 0.6;
  }
  60% {
    opacity: 0.8
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
