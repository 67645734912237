.tagsWrapper {
  overflow: auto;
  max-height: 90px;
}

.high {
  color: #ff002e;
}

.mid {
  color: #ffbc59;
}

.low {
  color: #3224c2;
}
