.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 360px;
}

.high {
  color: #ff002e;
}

.mid {
  color: #ffbc59;
}

.low {
  color: #3224c2;
}
