.container {
  display: grid;
  grid-template-columns: 17.7% auto;
  grid-template-rows: auto;
}

.headerRow > th:last-child > span:first-child > span:first-child {
  @apply rounded-tr-lg;
}

.headerRow > th:nth-child(2) > span:first-child > span:first-child {
  @apply rounded-tl-lg;
}

.singleCell {
  min-width: 120px;
}

@keyframes rotateRightAnimation {
  0% {
    transform: rotate(0);
    @apply text-neutralSecondary-60;
  }

  100% {
    transform: rotate(180deg);
    @apply text-primary-50;
  }
}

.arrowRotateRight {
  animation: rotateRightAnimation 0.5s ease-out forwards;
}

@keyframes rotateLeftAnimation {
  0% {
    transform: rotate(180deg);
    @apply text-primary-50;
  }

  100% {
    transform: rotate(0);
    @apply text-neutralSecondary-60;
  }
}

.arrowRotateLeft {
  animation: rotateLeftAnimation 0.5s ease-out forwards;
}
