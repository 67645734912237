.container {
  width: 40px;
  position: relative;
  height: 25px;
}

/* The slider */
.slider {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  line-height: 20px;
  text-transform: uppercase;
  color: rgb(var(--color-primary-100));
  background-color: rgb(var(--color-neutral-secondary-60));
  border-radius: 25px;
  width: 40px;
  height: 25px;
  cursor: pointer;
  transition: all 0.3 ease;
  border: solid 1px rgb(var(--color-primary-100));
}

.slider:before {
  position: absolute;
  content: "";
  display: inline-block;
  height: 17px;
  left: 2px;
  width: 17px;
  background: rgb(var(--color-primary-100));
  border-radius: 100%;
  transition: all 0.3s ease;
}
.input:checked + .slider {
  background: rgb(var(--color-other-cyan));
}

.input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* secondary variant */

.slider2 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  line-height: 20px;
  text-transform: uppercase;
  color: rgb(var(--color-primary-100));
  background-color: rgb(var(--color-neutral-secondary-90));
  border-radius: 25px;
  width: 40px;
  height: 25px;
  cursor: pointer;
  transition: all 0.3 ease;
  border: solid 1px rgb(var(--color-neutral-secondary-40));
}

.slider2:before {
  position: absolute;
  content: "";
  display: inline-block;
  height: 17px;
  left: 2px;
  width: 17px;
  background: rgb(var(--color-other-toxic-green));
  border: solid 1px rgb(var(--color-primary-100));
  border-radius: 100%;
  transition: all 0.3s ease;
}

.input:checked + .slider2:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
  background: rgb(var(--color-error-50));
}
