.needsGrid {
  display: grid;
  grid-template-columns: 18% 12% 8% 10% 12% 12% 12% auto;
  grid-auto-flow: column;
  padding-left: 40px;
  padding-right: 30px;
}

.detailsHigh {
  min-height: 450px;
}

.usersGrid {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 28% 15% 15% 15% auto;
  padding-left: 24px;
  padding-right: 30px;
}

.specialistsGrid {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 28% 15% 15% 15% 5% auto;
  padding-left: 24px;
  padding-right: 30px;
}

.high {
  color: #ff002e;
}

.mid {
  color: #ffbc59;
}

.low {
  color: #3224c2;
}
