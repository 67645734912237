.backgroundCircle {
  fill: none;
  stroke: rgb(var(--color-neutral-secondary-60));
}

.meterCircle {
  fill: none;
  stroke-width: 8px;
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke-dasharray: 360;
  animation: progress 1s ease-out;
}

@keyframes progress {
  0% { stroke-dashoffset: 360; }
}
