.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
  border-bottom: inherit;
  border-right: inherit;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: "";
}

.arrow_top {
  bottom: -3px;
}

.arrow_top::before {
  transform: rotate(45deg);
}

.arrow_bottom {
  top: -3px;
  transform: rotate(180deg);
}

.arrow_bottom::before {
  transform: rotate(225deg);
}

.arrow_left {
  right: -3px;
}

.arrow_left::before {
  transform: rotate(225deg);
}

.arrow_right {
  left: -3px;
}

.arrow_right::before {
  transform: rotate(225deg);
}
