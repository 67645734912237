.container:hover ~ svg {
    color: rgb(var(--color-neutral-secondary-80));
}

.container:hover svg {
    color: rgb(var(--color-primary-50));
}

.container svg:hover,
.container svg:hover ~ svg {
    color: rgb(var(--color-neutral-secondary-80));
}

.container svg:hover {
    color: rgb(var(--color-primary-50));
}
