.gridContainer {
  display: grid;
  grid-template-columns: 20% 15% 16% 10% 8% 9% 5% 8% auto;
  grid-auto-flow: column;
  padding: 0 40px;
  gap: 1rem;
}

.modalWidth {
  width: 500px;
}

.proposalColumn {
  min-width: 252.5px;
  min-height: 439px;
}
