.gridContainer {
    display: grid;
    grid-template-columns: 26% 26% 26% 10% 10%;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
}

.newUsersGridContainer {
    grid-template-columns: 30% 40% 20% 10%;
    grid-auto-flow: column;
}
