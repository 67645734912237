body,
:root {
  --color-primary-100: 255, 255, 255 /* #FFFFFF */;
  --color-primary-95: 246, 248, 255 /* #F6F8FF */;
  --color-primary-85: 226, 229, 246 /* #E2E5F6 */;
  --color-primary-80: 187, 197, 245 /* #BBC5F5 */;
  --color-primary-71: 119, 136, 227 /* #7788E3 */;
  --color-primary-70: 119, 136, 227 /* #7788E3 */;
  --color-primary-60: 80, 102, 217 /* #5066D9 */;
  --color-primary-51: 47, 73, 209 /* #2F49D1 */;
  --color-primary-50: 47, 73, 209 /* #2F49D1 */;
  --color-primary-30: 21, 45, 168 /* #152DA8 */;
  --color-primary-20: 13, 34, 144 /* #0D2290 */;
  --color-primary-10: 9, 27, 118 /* #091B76 */;

  --color-secondary-95: 241, 243, 255 /* #F1F3FF */;
  --color-secondary-90: 220, 225, 255 /* #DCE1FF */;
  --color-secondary-80: 205, 213, 254 /* #CDD5FE */;
  --color-secondary-70: 134, 149, 231 /* #8695E7 */;
  --color-secondary-60: 117, 138, 255 /* #758AFF */;
  --color-secondary-50: 81, 108, 248 /* #516CF8 */;
  --color-secondary-40: 50, 79, 227 /* #324FE3 */;
  --color-secondary-30: 33, 60, 202 /* #213CCA */;
  --color-secondary-20: 21, 45, 168 /* #152DA8 */;

  --color-success-100: 255, 255, 255 /* #FFFFFF */;
  --color-success-99: 220, 249, 243 /* #DCF9F3 */;
  --color-success-95: 194, 254, 241 /* #C2FEF1 */;
  --color-success-90: 147, 255, 232 /* #93FFE8 */;
  --color-success-80: 123, 242, 220 /* #7BF2DC */;
  --color-success-70: 95, 225, 201 /* #5FE1C9 */;
  --color-success-60: 67, 209, 183 /* #43D1B7 */;
  --color-success-50: 0, 167, 143 /* #00A78F */;
  --color-success-40: 0, 121, 104 /* #007968 */;
  --color-success-30: 0, 98, 84 /* #006254 */;
  --color-success-20: 0, 75, 64 /* #004B40 */;
  --color-success-10: 0, 29, 25 /* #001D19 */;
  --color-success-0: 0, 0, 0 /* #000000 */;

  --color-error-100: 255, 255, 255 /* #FFFFFF */;
  --color-error-99: 252, 252, 252 /* #FCFCFC */;
  --color-error-95: 255, 240, 243 /* #FFF0F3 */;
  --color-error-90: 251, 221, 226 /* #FBDDE2 */;
  --color-error-80: 255, 158, 174 /* #FF9EAE */;
  --color-error-70: 255, 132, 150 /* #FF8496 */;
  --color-error-60: 240, 104, 124 /* #F0687C */;
  --color-error-50: 209, 67, 88 /* #D14358 */;
  --color-error-40: 199, 46, 68 /* #C72E44 */;
  --color-error-30: 154, 26, 44 /* #9A1A2C */;
  --color-error-20: 104, 0, 3 /* #680003 */;
  --color-error-10: 65, 0, 1 /* #410001 */;
  --color-error-0: 0, 0, 0 /* #000000 */;

  --color-neutral-primary-100: 255, 255, 255 /* #FFFFFF */;
  --color-neutral-primary-85: 240, 242, 245 /* #F0F2F5 */;
  --color-neutral-primary-50: 180, 184, 191 /* #B4B8BF */;
  --color-neutral-primary-40: 140, 150, 164 /* #8C96A4 */;
  --color-neutral-primary-30: 107, 116, 130 /* #6B7482 */;
  --color-neutral-primary-20: 42, 45, 51 /* #2A2D33 */;
  --color-neutral-primary-10: 27, 27, 31 /* #1B1B1F */;

  --color-neutral-secondary-90: 248, 249, 255 /* #F8F9FF */;
  --color-neutral-secondary-80: 208, 215, 225 /* #D0D7E1 */;
  --color-neutral-secondary-60: 208, 215, 225 /* #D0D7E1 */;
  --color-neutral-secondary-41: 160, 174, 195 /* #A0AEC3 */;
  --color-neutral-secondary-40: 160, 174, 195 /* #A0AEC3 */;
  --color-neutral-secondary-30: 118, 139, 169 /* #768BA9 */;
  --color-neutral-secondary-20: 90, 114, 147 /* #5A7293 */;
  --color-neutral-secondary-10: 44, 62, 87 /* #2C3E57 */;

  --color-warning-40: 255, 236, 228 /* #FFECE4 */;
  --color-warning-60: 255, 78, 0 /* #FF4E00 */;

  --color-other-cyan: 104, 183, 255 /* #68B7FF */;
  --color-other-toxic-green: 0, 254, 177 /* #00FEB1 */;
  --black-font: 25, 45, 50 /* #192D32 */;
  --color-other-low: 255, 197, 110 /* #ffc56e */;
}
