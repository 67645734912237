.background {
  background-image: url("../../../../../../images/prospect/shadow.png");
  background-position: 50% 62%;
  background-repeat: no-repeat;
  background-size: 34%;
  z-index: 50;
}

.teamList {
  max-height: 280px;
  min-height: 280px;
}
