.tableColumns {
    grid-template-areas:
    "type template modification total used unused";
    grid-template-rows: auto;
    grid-template-columns: 25% 15% 15% 15% 15% 15%;
}

.historyTableColumns {
    grid-template-areas:
    "date holidayType amountOfDays add";
    grid-template-rows: auto;
    grid-template-columns: 30% 20% 40% 10%;
    display: grid;
}

.chooseTemplateModal {
    max-width: 46rem;
}

.chooseTemplateModalIcon {
    height: 205px;
    width: 405px;
}
