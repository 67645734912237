.gridContainer {
    display: grid;
    grid-template-columns: auto 20% 20% 25% 10%;
    align-items: center;
}

.removeModalContent {
    width: 800px;
}

.pageContent {
    height: calc(100% - 70px);
}
