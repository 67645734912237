.gridContainer {
  display: grid;
  grid-template-columns: 25% 15% 10% 10% 10% 12% 15% 3%;
  grid-auto-flow: column;
}

.addUserContainer {
  display: grid;
  grid-template-columns: 25% 15% 15% 15% auto;
  grid-auto-flow: column;
}
