.tableColumns {
  display: grid;
  grid-template-areas:
    "availability name profession seniority location status reson days profession ml"
    "row row row row row row row row row";
  grid-template-columns: 10% 24% 10% 10% 15% 16% 8% 4% 4%;
  grid-template-rows: auto;
}

.tableColumnsProduction {
  display: grid;
  grid-template-areas: "row row row row row row row row";
  grid-template-columns: 10% 25% 14% 14% 15% 15% 4% 3%;
  grid-template-rows: auto;
}

.foldersStatusesTableColumns {
  display: grid;
  grid-template-areas: "folderName status note toggle";
  grid-template-columns: 30% 15% auto 5%;
  grid-template-rows: auto;
}

.foldersStatusesTableColumns:last-child {
  border: none;
}
