.overviewContainer {
    grid-template-columns: auto 23%;
    grid-template-rows: 100%;
}

.detailsContainer {
    column-gap: 7px;
    display: grid;
    grid-template-columns: 16% auto;
    grid-template-rows: repeat(auto-fit, auto);
}

.detailsContainerEdit {
    display: grid;
    grid-template-columns: 16% auto;
    grid-template-rows: repeat(auto-fit, auto);
    column-gap: 7px;
}

@media only screen and (max-width: 1350px) {
    .overviewContainer {
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
    }
}

.editOpen {
    animation: slideDown .4s ease;
}

@keyframes slideDown {
    from {
        height: 20%;
    }

    to {
        height: 100%;
    }
}
