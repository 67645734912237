.tab:after {
  background: rgb(var(--color-primary-60));
  border-radius: 5px 5px 0 0;
  display: block;
  content: "";
  height: 2px;
  margin-top: auto;
  transition: width 0.3s;
  width: 0;
}

.tabActive:after {
  width: 100%;
}

.tabInactive:after {
  transition: width 0.3s;
  width: 0;
}
