@keyframes pulse {
  0% {
    width: 10px;
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    width: 120%;
    opacity: 0;
  }
}

.headerContainer {
  align-items: center;
  border-radius: 0.5rem;
  border-width: 1px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 30% auto 6% 3.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.rowContainer {
  align-items: center;
  border-radius: 0.5rem;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 30% auto 6% 3.5rem;
}

.timeEntriesContainer {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
  place-items: center;
  white-space: nowrap;
  height: 100%;
}

.deleteModal {
  max-width: 48rem;
}

.loader {
  animation: pulse 1.5s ease-in infinite;
  width: 100%;
}

.weekendEntry:nth-child(6),
.weekendEntry:nth-child(7) {
  background: rgba(var(--color-neutral-secondary-20), 0.06);
}
