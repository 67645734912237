[data-font-size="large"] {
  --font-size-xs: 16px;
  --font-size-sm: 18px;
  --font-size-base: 20px;
  --font-size-lg: 24px;
  --font-size-xl: 30px;
  --font-size-2xl: 36px;
  --font-size-3xl: 48px;
  --font-size-4xl: 60px;
  --font-size-5xl: 72px;
  --font-size-6xl: 80px;
  --font-size-7xl: 88px;
  --font-size-8xl: 96px;
  --font-size-9xl: 128px;
}
