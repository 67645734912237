[data-color-theme="dark"] {
  --color-primary-100: 255, 255, 255 /* #FFFFFF */;
  --color-primary-95: 61, 85, 108 /* #3D556C */;
  --color-primary-85: 229, 241, 246 /* #E5F1F6 */;
  --color-primary-80: 38, 197, 248, /* #26C5F8 */;
  --color-primary-71: 87, 182, 212 /* #57B6D4 */;
  --color-primary-70: 125, 224, 255 /* #7DE0FF */;
  --color-primary-60: 67, 206, 251 /* #43CEFB */;
  --color-primary-51: 38, 197, 248 /* #26C5F8 */;
  --color-primary-50: 38, 197, 248 /* #26C5F8 */;
  --color-primary-30: 5, 161, 210 /* #05A1D2 */;
  --color-primary-20: 2, 98, 129 /* #026281 */;
  --color-primary-10: 4, 28, 52 /* #041C34 */;

  --color-secondary-95: 226, 241, 246 /* #E2F1F6 */;
  --color-secondary-90: 206, 252, 255 /* #CEFCFF */;
  --color-secondary-80: 190, 243, 255 /* #BEF3FF */;
  --color-secondary-70: 138, 227, 255 /* #8AE3FF */;
  --color-secondary-60: 117, 222, 255 /* #75DEFF */;
  --color-secondary-50: 112, 220, 255 /* #70DCFF */;
  --color-secondary-40: 59, 177, 228 /* #3BB1E4 */;
  --color-secondary-30: 59, 177, 228 /* #3BB1E4 */;
  --color-secondary-20: 4, 150, 197 /* #0496C5 */;

  --color-success-100: 0, 0, 0 /* #000000 */;
  --color-success-99: 220, 249, 243 /* #DCF9F3 */;
  --color-success-95: 194, 254, 241 /* #C2FEF1 */;
  --color-success-90: 147, 255, 232 /* #93FFE8 */;
  --color-success-80: 123, 242, 220 /* #7BF2DC */;
  --color-success-70: 95, 225, 201 /* #5FE1C9 */;
  --color-success-60: 67, 209, 183 /* #43D1B7 */;
  --color-success-50: 0, 167, 143 /* #00A78F */;
  --color-success-40: 0, 121, 104 /* #007968 */;
  --color-success-30: 0, 98, 84 /* #006254 */;
  --color-success-20: 0, 75, 64 /* #004B40 */;
  --color-success-10: 0, 29, 25 /* #001D19 */;
  --color-success-0: 255, 255, 255 /* #FFFFFF */;

  --color-error-100: 0, 0, 0 /* #000000 */;
  --color-error-99: 89, 5, 6 /* #590506 */;
  --color-error-95: 129, 27, 30 /* #811B1E */;
  --color-error-90: 251, 221, 226 /* #FBDDE2 */;
  --color-error-80: 255, 158, 174 /* #FF9EAE */;
  --color-error-70: 255, 132, 150 /* #FF8496 */;
  --color-error-60: 240, 104, 124 /* #F0687C */;
  --color-error-50: 209, 67, 88 /* #D14358 */;
  --color-error-40: 199, 46, 68 /* #C72E44 */;
  --color-error-30: 154, 26, 44 /* #9A1A2C */;
  --color-error-20: 255, 240, 243 /* #FFF0F3 */;
  --color-error-10: 252, 252, 252 /* #FCFCFC */;
  --color-error-0: 255, 255, 255 /* #FFFFFF */;

  --color-neutral-primary-100: 25, 46, 50 /* #192E32 */;
  --color-neutral-primary-85: 5, 12, 15 /* #050C0F */;
  --color-neutral-primary-50: 144, 191, 194 /* #90BFC2 */;
  --color-neutral-primary-40: 87, 182, 212 /* #57B6D4 */;
  --color-neutral-primary-30: 101, 207, 240 /* #65CFF0 */;
  --color-neutral-primary-20: 255, 255, 255 /* #FFFFFF */;
  --color-neutral-primary-10: 220, 236, 253 /* #DCECFD */;

  --color-neutral-secondary-90: 54, 76, 97 /* #364C61 */;
  --color-neutral-secondary-80: 189, 224, 235 /* #BDE0EB */;
  --color-neutral-secondary-60: 163, 209, 224 /* #A3D1E0 */;
  --color-neutral-secondary-41: 38, 197, 248 /* #26C5F8 */;
  --color-neutral-secondary-40: 25, 158, 201 /* #199EC9 */;
  --color-neutral-secondary-30: 118, 157, 169 /* #769DA9 */;
  --color-neutral-secondary-20: 240, 242, 245 /* #F0F2F5 */;
  --color-neutral-secondary-10: 6, 94, 122 /* #065E7A */;

  --color-warning-40: 69, 36, 23 /* #452417 */;
  --color-warning-60: 255, 78, 0 /* #FF4E00 */;

  --color-other-cyan: 104, 210, 255 /* #68D2FF */;
  --color-other-toxic-green: 0, 254, 177 /* #00FEB1 */;
  --black-font: 25, 45, 50 /* #192D32 */;
  --color-other-low: 255, 197, 110 /* #ffc56e */;
}
