[data-color-theme="highContrast"] {
  --color-primary-100: 20, 20, 20 /* #141414 */;
  --color-primary-95: 0, 0, 0 /* #000000 */;
  --color-primary-85: 0, 0, 0 /* #000000 */;
  --color-primary-80: 252, 184, 39 /* #FCB827 */;
  --color-primary-71: 252, 184, 39 /* #FCB827 */;
  --color-primary-70: 252, 184, 39 /* #FCB827 */;
  --color-primary-60: 252, 184, 39 /* #FCB827 */;
  --color-primary-51: 252, 184, 39 /* #FCB827 */;
  --color-primary-50: 252, 184, 39 /* #FCB827 */;
  --color-primary-30: 255, 255, 255 /* #FFFFFF */;
  --color-primary-20: 252, 184, 39 /* #FCB827 */;
  --color-primary-10: 228, 164, 26 /* #E4A41A */;

  --color-secondary-95: 0, 0, 0 /* #000000 */;
  --color-secondary-90: 255, 255, 255 /* #ffffff */;
  --color-secondary-80: 252, 184, 39 /* #FCB827 */;
  --color-secondary-70: 241, 214, 169 /* #F1D6A7 */;
  --color-secondary-60: 252, 184, 39 /* #FCB827 */;
  --color-secondary-50: 252, 184, 39 /* #FCB827 */;
  --color-secondary-40: 252, 184, 39 /* #FCB827 */;
  --color-secondary-30: 252, 184, 39 /* #FCB827 */;
  --color-secondary-20: 252, 184, 39 /* #FCB827 */;

  --color-success-100: 20, 20, 20 /* #141414 */;
  --color-success-99: 0, 0, 0 /* #000000 */;
  --color-success-95: 0, 0, 0 /* #000000 */;
  --color-success-90: 255, 255, 255 /* #ffffff */;
  --color-success-80: 123, 242, 220 /* #7BF2DC */;
  --color-success-70: 252, 184, 39 /* #FCB827 */;
  --color-success-60: 252, 184, 39 /* #FCB827 */;
  --color-success-50: 252, 184, 39 /* #FCB827 */;
  --color-success-40: 252, 184, 39 /* #FCB827 */;
  --color-success-30: 228, 164, 26 /* #E4A41A */;
  --color-success-20: 252, 184, 39 /* #FCB827 */;
  --color-success-10: 255, 255, 255 /* #ffffff */;
  --color-success-0: 255, 255, 255 /* #ffffff */;

  --color-error-100: 20, 20, 20 /* #141414 */;
  --color-error-99: 0, 0, 0 /* #000000 */;
  --color-error-95: 255, 255, 255 /* #ffffff */;
  --color-error-90: 255, 255, 255 /* #ffffff */;
  --color-error-80: 255, 255, 255 /* #ffffff */;
  --color-error-70: 252, 184, 39 /* #FCB827 */;
  --color-error-60: 252, 184, 39 /* #FCB827 */;
  --color-error-50: 252, 184, 39 /* #FCB827 */;
  --color-error-40: 252, 184, 39 /* #FCB827 */;
  --color-error-30: 228, 164, 26 /* #E4A41A */;
  --color-error-20: 252, 184, 39 /* #FCB827 */;
  --color-error-10: 252, 184, 39 /* #FCB827 */;
  --color-error-0: 252, 184, 39 /* #FCB827 */;

  --color-neutral-primary-100: 20, 20, 20 /* #141414 */;
  --color-neutral-primary-85: 0, 0, 0 /* #000000 */;
  --color-neutral-primary-50: 255, 255, 255 /* #ffffff */;
  --color-neutral-primary-40: 252, 184, 39 /* #FCB827 */;
  --color-neutral-primary-30: 252, 184, 39 /* #FCB827 */;
  --color-neutral-primary-20: 255, 255, 255 /* #FFFFFF */;
  --color-neutral-primary-10: 27, 27, 31 /* #1B1B1F */;

  --color-neutral-secondary-90: 20, 20, 20 /* #141414 */;
  --color-neutral-secondary-80: 252, 184, 39 /* #FCB827 */;
  --color-neutral-secondary-60: 255, 255, 255 /* #FFFFFF */;
  --color-neutral-secondary-41: 252, 184, 39 /* #FCB827 */;
  --color-neutral-secondary-40: 252, 184, 39 /* #FCB827 */;
  --color-neutral-secondary-30: 252, 184, 39 /* #FCB827 */;
  --color-neutral-secondary-20: 252, 184, 39 /* #FCB827 */;
  --color-neutral-secondary-10: 255, 255, 255 /* #FFFFFF */;

  --color-warning-40: 69, 36, 23 /* #452417 */;
  --color-warning-60: 2252, 184, 39 /* #FCB827 */;

  --color-other-cyan: 25, 45, 50 /* #192D32 */;
  --color-other-toxic-green: 252, 184, 39 /* #FCB827 */;
  --black-font: 25, 45, 50 /* #192D32 */;
  --color-other-low: 255, 197, 110 /* #ffc56e */;
}
