.navbarLinksOpen {
    transition: height .5s ease 0.2s, opacity .2s ease .2s;
}

.navbarLinksClose {
    transition: height ease .5s, opacity .6s ease .6s;
}

.navbar {
    -ms-overflow-style: none;
    scrollbar-width: none;  
}

.navbar::-webkit-scrollbar {
    display: none;
}

