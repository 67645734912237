.containerHeight {
  height: 730px;
}

.containerFullWidth {
  width: 72rem;
}

.containerPartialWidth {
  width: 36rem;
}

@keyframes fullWidth {
  0% {
    width: 36rem;
  }

  100% {
    width: 72rem;
  }
}

@keyframes partialWidth {
  0% {
    width: 72rem;
    opacity: 0.5;
  }

  100% {
    width: 36rem;
    opacity: 1;
  }
}

.modalFullAnimationWidth {
  width: 72rem;
  animation: fullWidth 0.5s ease-in;
}

@keyframes contentModal {
  0% {
    opacity: 0;
    width: 0;
  }

  100% {
    opacity: 1;
    width: 50%;
  }
}

.modalContent {
  animation: contentModal 2s ease-in;
}

.modalPartialAnimationWidth {
  width: 36rem;
  animation: partialWidth 0.4s ease-in;
}

.cancelingModalWidth {
  width: 800px;
}

.cancelingModalImage {
  height: 200px;
}

.calendar > input {
  height: 100%;
  background-color: rgb(var(--color-neutral-primary-100));
  color: rgb(var(--color-neutral-primary-20));
  width: 100% !important;
}

@keyframes right {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.rightColumn {
  animation: right 0.5s ease-in;
}

.calendarIcon {
  top: 10px;
}

.containerPartialWidth {
  width: 36rem;
}

@keyframes fullWidth {
  0% {
    width: 36rem;
  }

  100% {
    width: 72rem;
  }
}

@keyframes partialWidth {
  0% {
    width: 72rem;
    opacity: 0.5;
  }

  100% {
    width: 36rem;
    opacity: 1;
  }
}

.modalFullAnimationWidth {
  width: 72rem;
  animation: fullWidth 0.5s ease-in;
}

@keyframes contentModal {
  0% {
    opacity: 0;
    width: 0;
  }

  100% {
    opacity: 1;
    width: 50%;
  }
}

.modalContent {
  animation: contentModal 2s ease-in;
}

.modalPartialAnimationWidth {
  width: 36rem;
  animation: partialWidth 0.4s ease-in;
}

.cancelingModalWidth {
  width: 800px;
}

.cancelingModalImage {
  height: 200px;
}

.calendar > input {
  height: 100%;
  background-color: rgb(var(--color-neutral-primary-100));
  color: rgb(var(--color-neutral-primary-20));
  width: 100% !important;
}

@keyframes right {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.progressBar {
  animation: fill 2s ease-out infinite;
  animation-fill-mode: forwards;
}

.rightColumn {
  animation: right 0.5s ease-in;
}

.calendarIcon {
  top: 10px;
}

.groupDropdownOpenedContent {
  max-height: 280px;
}
