.tableColumns {
    grid-template-areas:
      "status name profession seniority location more"
     "details details details details details details";
    grid-template-rows: auto auto;
    grid-template-columns: 15% auto 13% 13% 13% 10%;
}

.postponedCardGrid {
    grid-template-rows: auto;
    grid-template-columns: 20% auto 10%;
}

.filterDropdownContent {
    max-height: 50vh;
}
