.senior {
  color: #4d66d3;
  font-weight: bold;
}

.regularPlus {
  color: #4dd7c4;
  font-weight: bold;
}

.regular {
  color: #7cd34d;
  font-weight: bold;
}

.juniorPlus {
  color: #e99a10;
  font-weight: bold;
}

.junior {
  color: #f1b81e;
  font-weight: bold;
}
