.container {
    display: grid;
    grid-template-columns: 20% auto;
    grid-template-rows: auto;
    grid-template-areas:
    "header header header"
    "main main . sidebar"
}

.lastColumnContainer {
    width: 100%;
    min-width: 130px;
}

.singleMiddleCell {
    width: 100%;
    min-width: 130px;
}

.singleMiddleCellActive{
    width: 100%;
    min-width: 260px;
}

.baseSingleCell {
    height: 50px;
    line-height: 1;
    display: flex;
    align-items: center;
}

.skeleton {
    position: relative;
    overflow: hidden;
}

.skeleton::before {
    content: "";
    display: block;
    position: absolute;
    left: -200px;
    top: 0;
    height: 100%;
    width: 200px;
    background: linear-gradient(
            to right,
            transparent 0%,
            rgba(var(--color-neutral-secondary-60), 1) 100%,
            transparent 100%
    );
    animation: load 1.1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
    from {
        left: -200px;
    }
    to {
        left: 100%;
    }
}

.activeShadow {
    box-shadow: 0 9px 0 0 white,
    0 0 0 0 white,
    10px 0 20px -8px rgb(var(--color-neutral-secondary-60)),
    -10px 0 20px -8px rgb(var(--color-neutral-secondary-60));
    z-index: 1;
}