body,
:root {
  --font-size-xs: 10px;
  --font-size-sm: 12px;
  --font-size-base: 14px;
  --font-size-lg: 16px;
  --font-size-xl: 18px;
  --font-size-2xl: 20px;
  --font-size-3xl: 24px;
  --font-size-4xl: 30px;
  --font-size-5xl: 36px;
  --font-size-6xl: 48px;
  --font-size-7xl: 60px;
  --font-size-8xl: 72px;
  --font-size-9xl: 96px;
}
