.groupRow4columns {
    grid-template-columns: 3% 15% 40% 30%;
}

.groupRow3columns {
    grid-template-columns: 3% 55% 0 30%;
}

.gridContainerGroupedBy {
    display: grid;
    grid-template-columns: 80% auto 8%;
    grid-auto-flow: column;
    padding-left: 40px;
    padding-right: 40px;
}

.firstRow {
    -webkit-box-shadow: 0 9px 18px -20px rgb(var(--color-neutral-primary-20));
    -moz-box-shadow: 0 9px 18px -20px rgb(var(--color-neutral-primary-20));
    box-shadow: 0 9px 18px -20px rgb(var(--color-neutral-primary-20));
}

.secondRow {
    -webkit-box-shadow: 0 9px 18px -18px rgb(var(--color-neutral-primary-20));
    -moz-box-shadow: 0 9px 18px -18px rgb(var(--color-neutral-primary-20));
    box-shadow: 0 9px 18px -18px rgb(var(--color-neutral-primary-20));
}

.thirdRow {
    -webkit-box-shadow: 0 9px 18px -18px rgb(var(--color-neutral-primary-20));
    -moz-box-shadow: 0 9px 18px -18px rgb(var(--color-neutral-primary-20));
    box-shadow: 0 9px 18px -18px rgb(var(--color-neutral-primary-20));
}
