.react-calendar {
  width: 350px;
  background: rgb(var(--color-neutral-primary-100));
  line-height: 1.125em;
  color: rgb(var(--color-neutral-primary-20));
  border-radius: 0.375rem;
  padding: 0.375rem;
  font-size: var(--font-size-base);
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 25px;
}

.react-calendar__navigation__arrow {
  background-color: rgb(var(--color-primary-80));
  color: rgb(var(--color-primary-100));
  border: 0;
  border-radius: 0.375rem;
  height: 25px;
  width: 25px;
}

.react-calendar__navigation__arrow:enabled:hover {
  opacity: 0.3;
  color: rgb(var(--color-primary-100));
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation button:disabled {
  color: rgb(var(--color-neutral-secondary-30));
  opacity: 0.3;
  cursor: not-allowed;
}

.react-calendar__navigation__label:hover,
.react-calendar__navigation__label:focus {
  color: rgb(var(--color-primary-50));
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--font-size-sm);
}

.react-calendar__month-view__weekdays__weekday abbr[title] {
  text-decoration: none;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-xs);
  font-weight: bold;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: rgb(var(--color-neutral-secondary-30));
  opacity: 0.3;
}

.react-calendar__tile {
  color: rgb(var(--color-neutral-primary-30));
  max-width: 100%;
  width: 10px;
  height: 48px;
  font-size: var(--font-size-base);
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  color: rgb(var(--color-neutral-secondary-30));
  opacity: 0.3;
  cursor: not-allowed;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  color: rgb(var(--color-primary-100));
  background-color: rgb(var(--color-primary-60));
  border: 0;
  border-radius: 0.375rem;
  --tw-bg-opacity: 0.8;
}

.react-calendar__tile--range {
  color: rgb(var(--color-secondary-70));
  background-color: rgb(var(--color-secondary-60));
  border-radius: 0;
}

.react-calendar__tile--range:enabled:focus,
.react-calendar__tile--range:enabled:hover {
  border: 0;
  border-radius: 0;
  color: rgb(var(--color-primary-100));
  background-color: rgb(var(--color-primary-60));
}

.react-calendar__tile--rangeStart {
  border: 0;
  border-radius: 0.375rem 0 0 0.375rem;
  color: rgb(var(--color-primary-100));
  background-color: rgb(var(--color-secondary-60));
}

.react-calendar__tile--rangeStart:enabled:hover {
  border: 0;
  border-radius: 0.375rem 0 0 0.375rem;
  color: rgb(var(--color-primary-100));
  background-color: rgb(var(--color-primary-60));
}

.react-calendar__tile--rangeStart:enabled:hover {
  border: 0;
  border-radius: 0.375rem 0 0 0.375rem;
  color: rgb(var(--color-primary-100));
  background-color: rgb(var(--color-primary-60));
}

.react-calendar__tile--rangeEnd {
  border-radius: 0 0.375rem 0.375rem 0;
  color: rgb(var(--color-primary-100));
  background-color: rgb(var(--color-primary-60));
}

.react-calendar__tile--rangeEnd:enabled:hover {
  border-radius: 0 0.375rem 0.375rem 0;
  color: rgb(var(--color-primary-100));
  background-color: rgb(var(--color-secondary-60));
}

.react-calendar__tile--now {
  position: relative;
}

.react-calendar__tile--now:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid;
  border-color: rgb(var(--color-secondary-30));
  border-radius: 0.375rem;
  width: 100%;
  height: 100%;
}

.react-calendar__tile--now-rangeStart {
  border: 0;
  border-radius: 0.375rem 0 0 0.375rem;
  color: rgb(var(--color-primary-100));
  background-color: rgb(var(--color-secondary-60));
}

.react-calendar__tile--now-rangeEnd {
  border: 0;
  border-radius: 0.375rem 0 0 0.375rem;
  color: rgb(var(--color-primary-100));
  background-color: rgb(var(--color-secondary-60));
}

.react-calendar__tile--rangeEnd:enabled:hover {
  border: 0;
  border-radius: 0 0.375rem 0.375rem 0;
  color: rgb(var(--color-primary-100));
  background-color: rgb(var(--color-primary-60));
}

.react-calendar__tile--active {
  color: rgb(var(--color-primary-100));
  background-color: rgb(var(--color-secondary-60));
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  opacity: 1;
  background-color: rgb(var(--color-primary-60));
  border-radius: none;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  opacity: 1;
  background-color: rgb(var(--color-primary-60));
  border-radius: none;
}

.react-calendar__tile--hasActive {
  background-color: rgb(var(--color-secondary-60));
}

.react-calendar--selectRange .react-calendar__tile--hover {
  color: rgb(var(--color-secondary-70));
  background-color: rgb(var(--color-secondary-95));
  opacity: 0.5;
}

.MuiPaper-root {
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", "sans-serif";
}

.MuiPickersMonth-monthButton,
button.MuiButtonBase-root.MuiPickersDay-root,
button > .MuiTouchRipple-root,
.MuiPickersCalendarHeader-label {
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", "sans-serif" !important;
}

.MuiPickersLayout-contentWrapper > .MuiDateCalendar-root {
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", "sans-serif" !important;
}

.MuiTextField-root > .MuiInputBase-root {
  @apply text-neutralPrimary-20 bg-neutralPrimary-100;
  font-size: 16px;
  font-family: "Poppins", "sans-serif";
  border-color: rgba(
    var(--color-neutral-secondary-60),
    var(--tw-border-opacity)
  );
  height: 35px;
  width: 100%;
}

.MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.MuiButtonBase-root.MuiPickersDay-root[aria-selected="false"],
.MuiPickersMonth-monthButton[aria-checked="false"],
.MuiPickersYear-yearButton,
.MuiPickersCalendarHeader-label,
.MuiPickersCalendarHeader-switchViewButton > svg,
.MuiDayCalendar-weekDayLabel {
  color: rgba(var(--color-neutral-primary-30)) !important;
}

.MuiPickersMonth-monthButton[aria-checked="true"],
.MuiPickersDay-dayWithMargin[aria-selected="true"] {
  @apply focus:ring;
}

.MuiPickersMonth-monthButton[aria-checked="false"],
.MuiPickersDay-dayWithMargin[aria-selected="false"] {
  @apply focus:ring-2;
}

.Mui-selected[aria-selected="true"],
.Mui-selected[aria-checked="true"] {
  background-color: rgba(var(--color-primary-50)) !important;
  color: rgba(var(--color-primary-100)) !important;
}

.MuiOutlinedInput-root > input[type="text"] {
  padding: 0 8px;
}

.MuiIconButton-root {
  @apply focus:ring-1 focus:ring-primary-50;
}

.MuiTextField-root > .MuiInputBase-root {
  @apply text-neutralPrimary-30 border border-neutralSecondary-60;
}

.MuiPaper-root {
  background-color: rgba(var(--color-neutral-primary-100)) !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}
