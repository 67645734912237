@tailwind base;
@tailwind components;
@tailwind utilities;

.react-datepicker__input-container {
  @apply flex justify-end bg-neutralPrimary-85 text-neutralPrimary-20 rounded-lg;
}

.react-datepicker-popper {
  @apply z-50 w-72 text-sm bg-neutralPrimary-100 shadow px-3 py-2 border rounded-lg;
}

.react-datepicker-left {
  @apply absolute left-0 right-auto top-11 transform-none !important;
}

.react-datepicker-right {
  @apply absolute right-0 left-auto top-11 transform-none !important;
}

.react-datepicker__portal {
  @apply absolute z-10 w-72 text-sm transform-none bg-neutralPrimary-100 shadow px-3 py-2 top-12 right-0 border-2 border-gray-200 rounded;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month,
.react-datepicker-year-header {
  @apply w-full flex items-center justify-center mb-4 font-semibold uppercase text-neutralPrimary-20;
}

.react-datepicker__week,
.react-datepicker__month-wrapper {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around  font-medium text-center text-xs;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full uppercase text-neutralPrimary-20;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply left-2 w-6 h-6 rounded transition flex items-center justify-center bg-primary-50 text-primary-100 hover:opacity-60 active:opacity-100;
}

.react-datepicker__navigation--previous::after {
  content: "<";
}

.react-datepicker__navigation--previous span {
  display: none;
}

.react-datepicker__navigation--next {
  @apply right-2 w-6 h-6 rounded transition flex items-center justify-center bg-primary-50 text-primary-100 hover:opacity-60 active:opacity-100;
}

.react-datepicker__navigation--next::after {
  content: ">";
}

.react-datepicker__navigation--next span {
  display: none;
}

.react-datepicker__day,
.react-datepicker__month-text {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-neutralPrimary-20 hover:bg-primary-60
    hover:bg-opacity-80 hover:text-primary-100 rounded-full;
}

.react-datepicker__month-text {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-neutralPrimary-20 hover:bg-primary-60
    hover:bg-opacity-80 hover:text-primary-100 rounded;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-200;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-neutralPrimary-70;
}

.react-datepicker__day--selected {
  @apply bg-primary-50 rounded-full text-primary-100;
}

.react-datepicker__month-text--selected {
  @apply bg-primary-50 rounded text-primary-100;
}

.react-datepicker__aria-live {
  display: none;
}

.react-datepicker__tab-loop {
  position: absolute;
  top: 0;
}

/* TODO if range select required
.react-datepicker__day--range-start {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__day--range-end {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-blue-500;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-blue-500;
}

*/

.MuiPaper-root {
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", "sans-serif";
}

.MuiPickersMonth-monthButton,
button.MuiButtonBase-root.MuiPickersDay-root,
button > .MuiTouchRipple-root,
.MuiPickersCalendarHeader-label {
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", "sans-serif" !important;
}

.MuiPickersLayout-contentWrapper > .MuiDateCalendar-root {
  @apply text-neutralPrimary-20 bg-neutralPrimary-100;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", "sans-serif" !important;
}

.MuiTextField-root > .MuiInputBase-root {
  @apply text-neutralPrimary-20 bg-neutralPrimary-100;
  font-size: 16px;
  font-family: "Poppins", "sans-serif";
  border-color: rgba(
          var(--color-neutral-secondary-60),
          var(--tw-border-opacity)
  );
  height: 35px;
  width: 100%;
}

.MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.MuiButtonBase-root.MuiPickersDay-root[aria-selected="false"],
.MuiPickersMonth-monthButton[aria-checked="false"] {
  color: rgba(var(--color-neutral-primary-30)) !important;
}

.MuiPickersMonth-monthButton[aria-checked="true"],
.MuiPickersDay-dayWithMargin[aria-selected="true"] {
  @apply focus:ring;
}

.MuiPickersMonth-monthButton[aria-checked="false"],
.MuiPickersDay-dayWithMargin[aria-selected="false"] {
  @apply focus:ring-2;
}

.Mui-selected[aria-selected="true"],
.Mui-selected[aria-checked="true"] {
  background-color: rgba(var(--color-primary-50)) !important;
  color: rgba(var(--color-primary-100)) !important;
}

.MuiOutlinedInput-root > input[type="text"] {
  padding: 0 8px;
}

.MuiIconButton-root {
  @apply focus:ring-1 focus:ring-primary-50;
}

.MuiTextField-root > .MuiInputBase-root {
  @apply text-neutralPrimary-30 border border-neutralSecondary-60;
}

.MuiPaper-root {
  background-color: rgba(var(--color-neutral-primary-100)) !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.Mui-error {
  margin-left: 0 !important;
}

