.month_grid {
  grid-template-rows: repeat(4, 1fr);
}

.year_grid {
  grid-template-rows: repeat(4, 1fr);
}

.day_grid {
  grid-template-rows: min-content repeat(6, minmax(0, 1fr));
}
