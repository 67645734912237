.skeleton {
    position: relative;
    overflow: hidden;
}

.skeleton::before {
    content: "";
    display: block;
    position: absolute;
    left: -200px;
    top: 0;
    height: 100%;
    width: 200px;
    background: linear-gradient(
            to right,
            transparent 0%,
            rgba(var(--color-neutral-primary-85), 0.5) 50%,
            transparent 100%
    );
    animation: load 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
    from {
        left: -200px;
    }
    to {
        left: 100%;
    }
}

.confirmBottomSection {
    margin-left: -35px;
}

.matrixUserRow {
    display: grid;
    grid-template-columns: 50% 25% 25%;
    grid-template-rows: repeat(auto-fit, auto);
    width: 100%;
    align-items: center;
}

@media only screen and (max-width: 1480px) {
    .matrixUserRow {
        grid-template-columns: 36% 32% 32%;
    }
}
