.togglebutton {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    line-height: 20px;
    text-transform: uppercase;
    color: rgb(var(--color-primary-100));
    background-color: rgb(var(--color-other-cyan));
    border: solid 2px rgb(var(--color-primary-100));
    border-radius: 25px;
    width: 40px;
    height: 25px;
    cursor: pointer;
    transition: all 0.3 ease;
}

.toggleicon {
    height: 20px;
    width: auto;
    padding: 0 4px;
}

.togglebutton::before {
    position: absolute;
    content: '';
    display: inline-block;
    height: 17px;
    left: 2px;
    width: 17px;
    background: rgb(var(--color-primary-100));
    border-radius: 100%;
    transition: all 0.3s ease;
}

.toggleinput:checked + .togglebutton {
    background: rgb(var(--color-primary-20));
    border: solid 1px rgb(var(--color-primary-100));

}
.toggleinput:checked + .togglebutton::before {
    left: 19px;
    background-color: rgb(var(--color-primary-100));
}
