.colorPicker .react-colorful__saturation {
    margin-bottom: 1.5rem;
    border-radius: 0.5rem;
}

.colorPicker .react-colorful__hue {
    border-radius: 0.5rem;
}

.colorPicker-hex input {
    border-radius: 4px;
    padding: 0 4px;
    background-color: rgb(var(--color-neutral-primary-100));
    border: 1px solid rgb(var(--color-neutral-secondary-60));
}

