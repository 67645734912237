.tableRowContainer {
    display: grid;
    grid-template-columns: 60% 30% 10%;
    grid-auto-flow: column;
}

@media only screen and (max-width: 1480px) {
    .tableRowContainer {
        grid-template-columns: 50% 40% 10%;
    }
}

@media only screen and (max-width: 1280px) {
    .tableRowContainer {
        grid-template-columns: 40% 50% 10%;
    }
}
